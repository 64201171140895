import Vue from "vue";
import Vuex from 'vuex';
import axios from 'axios';
import {router} from '../main.js';

function get_field_by_path( state, path )   {

    let s = path.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    let a = s.split('.');
    let field = state;
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in field) {
            field = field[k];
        } else  {
            break;
        }
    }

    return field;
}
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        ajax_loading : false,
        data_loaded: false,
        action: 'new',
        current_step: 0,
        steps: [{
            name: 'Method Statement',
            id: 'method_statement'
        },{
            name: 'Purpose',
            id: 'purpose'
        },{
            name: 'Roles and Responsibilities',
            id: 'roles_and_responsibilities'
        },{
            name: 'Procedure',
            id: 'procedure'
        },{
            name: 'Appendices',
            id: 'appendices'
        },{
            name: 'Documents',
            id: 'documents'
        },{
            name: 'Distribution',
            id: 'distribution'
        },{
            name: 'Finish',
            id: 'finish'
        }],
        sops_method_statement: [],
        sops_purpose: null,
        sops_roles_and_responsibilities: null,
        sops_procedure_fields: null,
        sops_procedure_steps: null,
        sops_finish: null,
        sops_appendices: null,
        sops_documents_fields: null,
        sops_documents_documents: null,
        sops_distribution: null,

        method_statement_texts: null,
        purpose_texts: null,
        roles_and_responsibilities_texts: null,
        procedure_texts: null,
        appendices_texts: null,
        documents_texts: null,
        distribution_texts: null,
        finish_texts: null,

        submission_state: 'initial',
        submission_rating: null

    },
    mutations: {

        ajax_loading: ( state, payload )=>  {

            state.ajax_loading = payload;

        },

        update_field: ( state, payload ) => {

            console.log(payload.store, payload.name, payload.value);

            const field = get_field_by_path( state, payload.store );

            field.value = payload.value;
/*
            for( let i = 0; i < state[ payload.store ].length; i++ )   {
                if( state[ payload.store ][ i ].name === payload.name )    {
                    state[ payload.store ][ i ].value = payload.value;
                    break;
                }
            }
*/
        },

        set_rating: ( state, payload ) =>   {

            state.submission_rating = payload;

        },

        set_step: ( state, payload ) => {

            if( payload < 1 )   {

                state.current_step = 0;
                return;

            }

            if( payload > state.steps.length - 1 )  {

                state.current_step = state.steps.length - 1;
                return;

            }

            state.current_step = payload;

        },

        add_repeater_item: ( state, payload )=> {

            const field = get_field_by_path( state, payload.store );

            field.value.push( payload.data );

        },

        remove_repeater_item: ( state, payload )=> {

            const field = get_field_by_path( state, payload.store );
            const idx = field.value.indexOf( payload.id );
            field.value.splice( idx, 1 );

        },

        update_repeater_item: ( state, payload )=> {

            const field = get_field_by_path( state, payload.store );
            field.value[ payload.index ] = payload.data;

        },

        change_repeater_item_index: ( state, payload )=>    {

            const field = get_field_by_path( state, payload.store );
            const a = field.value[ payload.from ];
            const b = field.value[ payload.to ];
            field.value[ payload.from ] = b;
            field.value[ payload.to ] = a;

        },

        add_checkbox_choice: ( state, payload ) =>  {

            const field = get_field_by_path( state, payload.store );

            field.choices.push({
                id: payload.id,
                name: payload.name,
                value: '',
                label: '',
                type: 'text'
            });

            field.checked_items.push( payload.id );

        },

        remove_checkbox_choice: ( state, payload ) =>  {

            const field = get_field_by_path( state, payload.store )

            field.choices.splice( payload.index, 1 );
            const idx = field.checked_items.indexOf( payload.id );
            field.checked_items.splice( idx, 1 );

        },
//todo pri niektorych riskoch sa ulozi ID pri niektorych value. treba to fixnut nech je to vzdy ID

        // myslim, ze toto som vyriesil na strane servera a momentalne to funguje
        update_checkbox_choice: ( state, payload ) =>  {

            const field = get_field_by_path( state, payload.store );

            if( payload.type === 'textfield' )  {

                field.choices[ payload.index ].value = payload.value;

            } else if( payload.type === 'checkbox' )    {

                // const idx = state[ payload.store ][ payload.field_index ].checked_items.indexOf( payload.id );
                const idx = field.checked_items.indexOf( payload.id );

                if( idx === -1 )    {

                    // state[ payload.store ][ payload.field_index ].checked_items.push( payload.id );
                    field.checked_items.push( payload.id );

                } else  {

                    // state[ payload.store ][ payload.field_index ].checked_items.splice( idx, 1 );
                    field.checked_items.splice( idx, 1 );

                }

            }

        },

        set_submission_status:( state, payload ) => {

            state.submission_state = payload;

        },

        update_submission: ( state, payload )   =>  {

            state.action = 'edit';
            state.intro_read = true;
            console.log(payload);
            Vue.localStorage.set( 'token', payload );

        },

        set_initial_data: ( state, payload ) => {

            state.data_loaded = true;
            state.sops_method_statement = payload.data.sops_method_statement;
            state.sops_purpose = payload.data.sops_purpose;
            state.sops_roles_and_responsibilities = payload.data.sops_roles_and_responsibilities;
            state.sops_procedure_fields = payload.data.sops_procedure.fields;
            state.sops_procedure_steps = payload.data.sops_procedure.steps;
            state.sops_appendices = payload.data.sops_appendices;
            state.sops_documents_fields = payload.data.sops_documents.fields;
            state.sops_documents_documents = payload.data.sops_documents.documents;
            state.sops_distribution = payload.data.sops_distribution;

            // texts
            state.method_statement_texts = payload.data.texts.method_statement;
            state.purpose_texts = payload.data.texts.purpose;
            state.roles_and_responsibilities_texts = payload.data.texts.roles_and_responsibilities;
            state.procedure_texts = payload.data.texts.procedure;
            state.appendices_texts = payload.data.texts.appendices;
            state.documents_texts = payload.data.texts.documents;
            state.distribution_texts = payload.data.texts.distribution;
            state.finish_texts = payload.data.texts.finish;

            if( payload.data.action === 'edit' )   {

                state.action = 'edit';
                state.intro_read = true;

            }

        }

    },
    actions: {
        upload( { commit }, form_data ) {

            commit( 'ajax_loading', true );

            const token = Vue.localStorage.get( 'token' );
            const base_uri = Vue.config.API_UPLOAD_URL + token;

            return axios.post( base_uri, form_data )
                .then( x => x.data )
                .finally( () => commit( 'ajax_loading', false ) );

        },
        init({ commit }, payload ){

            commit( 'ajax_loading', true );

            const promise = new Promise( ( resolve, reject ) => {

                const base_uri = Vue.config.API_URL + payload.token;

                axios.get( base_uri )
                    .then( ( result ) => {

                        // console.log( result );
                        Vue.localStorage.set( 'token', payload.token );
                        commit( 'set_initial_data', result );

                        resolve();

                    } ).catch( error => {

                        console.log('push_error');
                        router.push( '/error' );
                        reject( error );

                    } ).finally( () => commit( 'ajax_loading', false ) );

            } ).catch( error=> {
                console.log( error );
            } );
            return promise;

        },
        submit( { commit, state } )    {

            commit( 'ajax_loading', true );

            const promise = new Promise( ( resolve, reject ) => {

                const token = Vue.localStorage.get( 'token' );
                const base_uri = Vue.config.API_URL + token;

                if( state.action === 'new' )    {

                    axios.post( base_uri, state  )
                        .then( ( result ) => {
    // console.log(result);
                            // window.location.href = result.data.return_url;
                            resolve( result );

                        } ).catch( error => {

                            reject( error );
                            commit( 'set_submission_status', 'error' );

                        } ).finally( () => commit( 'ajax_loading', false ) );

                } else if( state.action === 'edit' )    {

                    axios.put( base_uri, state  )
                        .then( ( result ) => {
                            // console.log(result);
                            // window.location.href = result.data.return_url;
                            resolve( result );

                        } ).catch( error => {

                            reject( error );
                            commit( 'set_submission_status', 'error' );

                        } ).finally( () => commit( 'ajax_loading', false ) );

                }


            } ).catch( error=> {

                console.log( error );

            } );
            return promise;

        }
    }

});