<template>
  <div class="repeater">
      <div v-for="( item, index ) in value" :key="item.name" class="repeater_item">
        <component v-bind:is="repeater_component"
                   v-bind:index="index"
                   v-bind:step_count="value.length"
                   v-bind:fields_config="fields_config"
                   v-bind:value="item"
                   v-bind:label="item.label"

                   v-on:remove_step="remove_repeater_item"
                   v-on:update_step="update_repeater_item"
                   v-on:change_index="change_repeater_item_index"></component>
      </div>
    <!--
    v-bind:name="item.name"
            v-bind:heading="item.heading"
            v-bind:description="item.description"
            v-bind:value="item.value"
            v-bind:info="item.info"

    -->
      <div class="button_row">
        <a href="#add" role="button" class="primary" v-on:click.prevent="add_repeater_item">{{add_new_label}}</a>
      </div>
  </div>
</template>

<script>
import Step from "@/components/Step";
import Appendix from "@/components/Appendix";
import Person from "@/components/Person";
import Document from "@/components/Document";

export default {
  name: "Input_Repeater",
  components: { Step, Appendix, Person, Document },
  props: {
    add_new_label: String,
    add_new_data: Object,
    repeater_component: String,
    field_index: Number,
    store: String,
    value: Array,
    fields_config: Array
  },
  methods: {
    add_repeater_item: function()  {

      let sample_data = {};
      // const next_step = this.value.length + 1;

      for( let i = 0; i < this.fields_config.length; i++ )  {

        sample_data[ this.fields_config[ i ].name ] = '';

      }
/*
      if( this.$props.repeater_component === 'Step' ) {

        sample_data = {
          name: 'step_' + next_step,
          title: 'Step ' + next_step,
          description: ''
        };

      } else if( this.$props.repeater_component === 'Input_File' )  {

        sample_data = {
          key: 'field_' + next_step,
          label: 'Document Description',
          name: '',
          type: 'file',
          value: ''
        };

      } else if( this.$props.repeater_component === 'Person' )  {

        sample_data = {
          name: 'step_' + next_step,
          title: 'Person #' + next_step
        }

      } else if( this.$props.repeater_component === 'Person' )  {

        sample_data = {

          name: 'step_' + next_step,
          title: 'Person #' + next_step

        }

      }
*/
      const payload = {
        store: this.store,
        data: sample_data
      }
      this.$emit( 'add_repeater_item', payload );

    },
    remove_repeater_item: function( payload )  {

      payload.store = this.store;
      this.$emit( 'remove_repeater_item', payload );

    },
    update_repeater_item: function( payload )  {

      payload.store = this.store;
      this.$emit( 'update_repeater_item', payload );
      this.$forceUpdate();

    },
    change_repeater_item_index: function( payload ) {

      payload.store = this.store;
      this.$emit( 'change_repeater_item_index', payload );
      this.$forceUpdate();

    }
  }
}
</script>

<style scoped>

</style>