<template>

  <section>

    <section class="block">
      <section>
        <h3 v-text="text.summary_title"></h3>
        <p v-html="text.summary_intro"></p>
      </section>

      <section class="summary">
        <div>
          <strong>{{summary_method_statement.what_is_your_activity_label}}</strong>
          <p>{{summary_method_statement.what_is_your_activity_value}}</p>
        </div>
        <div>
          <strong>{{summary_method_statement.venue_label}}</strong>
          <p>{{summary_method_statement.venue_value}}</p>
        </div>
        <div>
          <strong>{{summary_method_statement.author_label}}</strong>
          <p>{{summary_method_statement.author_value}}</p>
        </div>
        <div>
          <strong>{{summary_method_statement.review_period_label}}</strong>
          <p>{{summary_method_statement.review_period_value}}</p>
        </div>
      </section>

      <section class="summary">
        <div>
          <strong>{{summary_roles_and_responsibilities.responsible_for_process_label}}</strong>
          <p>{{summary_roles_and_responsibilities.responsible_for_process_value}}</p>
        </div>
        <div>
          <strong>{{summary_roles_and_responsibilities.responsible_for_execution_label}}</strong>
          <p>{{summary_roles_and_responsibilities.responsible_for_execution_value}}</p>
        </div>
      </section>

    </section>

    <section class="block">

      <section>
        <h3 v-text="text.title"></h3>
        <p v-html="text.intro"></p>
      </section>

      <section>
        <div v-html="text.text"></div>
        <div class="form_rating">
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(5)" v-bind:class="{active: rating === 5 }">
            <img src="../assets/rating_5.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(4)" v-bind:class="{active: rating === 4 }">
            <img src="../assets/rating_4.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(3)" v-bind:class="{active: rating === 3 }">
            <img src="../assets/rating_3.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(2)" v-bind:class="{active: rating === 2 }">
            <img src="../assets/rating_2.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(1)" v-bind:class="{active: rating === 1 }">
            <img src="../assets/rating_1.png">
          </a>
        </div>
      </section>

      <section class="button_row">
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="submit" v-text="next_step_text"></a>
        <router-link v-bind:to="{ name: 'distribution' }" role="button">Back</router-link>
      </section>

    </section>

  </section>

</template>

<script>

import store from '../store/store.js';

export default {
  name: "Finish",
  data: function()  {
    return {
      is_loading: false,
      text: store.state.finish_texts
    }
  },
  metaInfo: {
    title: 'Finish'
  },
  computed: {

    summary_method_statement : function()  {

      let data = {
        what_is_your_activity_label : '',
        what_is_your_activity_value : '',
        venue_label : '',
        venue_value : '',
        author_label : '',
        author_value : '',
        review_period_label : '',
        review_period_value : '',
      }

      for(let i = 0; i < store.state.sops_method_statement.length; i++ )  {

        const field = store.state.sops_method_statement[ i ];
        switch( field.name )  {

          case "what_is_your_activity":
            data.what_is_your_activity_label = field.label;
            data.what_is_your_activity_value = field.value;
            break;
          case "venue":
            data.venue_label = field.label;
            data.venue_value = this.get_select_label( field );
            break;
          case "author":
            data.author_label = field.label;
            data.author_value = field.value;
            break;
          case "review_period":
            data.review_period_label = field.label;
            data.review_period_value = this.get_selected_radio_label( field );
            break;

        }

      }

      return data;

    },

    summary_roles_and_responsibilities : function() {

      let data = {
        responsible_for_task_label : '',
        responsible_for_task_value : '',
        responsible_for_execution_label : '',
        responsible_for_execution_value : '',
      }

      for(let i = 0; i < store.state.sops_roles_and_responsibilities.length; i++ )  {

        const field = store.state.sops_roles_and_responsibilities[ i ];
        switch( field.name )  {

          case "responsible_for_process":

            data.responsible_for_process_label = field.label;
            data.responsible_for_process_value = this.get_select_label( field );
            break;

          case "responsible_for_execution":

            data.responsible_for_execution_label = field.label;
            data.responsible_for_execution_value = this.get_selected_checkbox_labels( field );
            break;

        }

      }

      return data;

    },

    rating : function() {

      return store.state.submission_rating;

    },

    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Submit';

    }

  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    submit : function() {

      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {

        window.location.href = result.data.return_url;

      } );

      return false;

    },
    set_rating : function( rating ) {

      store.commit( 'set_rating', rating );

    }

  },
  mounted() {

    store.commit( 'set_step', 7 );

  }

}
</script>

<style scoped>

</style>