<template>

  <div class="procedure_step">

    <section class="step_summary">

      <div>
        <p class="step_no">{{step}}</p>
      </div>

      <div class="step_desc">
        <h3>{{heading}}</h3>
        <div v-html="description"></div>
      </div>

      <div class="step_controls">
        <a href="#edit" role="button" class="secondary" v-on:click.prevent="toggle_edit">Edit</a>
        <a href="#delete" role="button" class="secondary" v-on:click.prevent="remove_step">Delete</a>
        <a href="#up" role="button" class="secondary" v-on:click.prevent="move_up"><i class="icon-arrow-up"></i></a>
        <a href="#down" role="button" class="secondary" v-on:click.prevent="move_down"><i class="icon-arrow-down"></i></a>
      </div>

    </section>

    <section v-if="editing" class="form step_form">
      <Input_Text
           v-bind:label="field_def_heading.label" v-bind:name="field_def_heading.name" v-bind:value="step_heading" v-on:changed="update_title"
      ></Input_Text>

      <Input_Wysiwyg
           v-bind:label="field_def_description.label" v-bind:name="field_def_description.name" v-bind:value="step_description" v-on:changed="update_description"
      ></Input_Wysiwyg>

      <div class="button_row">
        <a href="#save" class="primary" role="button" v-on:click.prevent="update_step">Ok</a>
        <a href="#cancel" role="button" v-on:click.prevent="toggle_edit">Cancel</a>
      </div>

    </section>

  </div>
</template>

<script>
import Input_Text from "@/components/Input_Text";
import Input_Wysiwyg from "@/components/Input_Wysiwyg";

export default {
  name: "Step",
  props: {
    index: Number,
    step_count: Number,
    value: Object,
    fields_config: Array
  },
  watch:  {
    step_count: function()  {
      this.editing = false;
    }
  },
  mounted() {

    for ( let i = 0; i < this.fields_config.length; i++ ) {

      const f = this.fields_config[ i ];

      if( f.name === 'heading' ) {

        this.field_def_heading = f;

      } else if( f.name === 'description' )  {

        this.field_def_description = f;

      }

    }

    this.editing = true;
  },
  data: function()  {
    return {
      heading: this.$props.value.heading,
      description: this.$props.value.description,
      step_heading: this.$props.value.heading,
      step_description: this.$props.value.description,
      field_def_heading: null,
      field_def_description: null,
      editing: false
    }
  },
  computed: {

    step: function()  {

      return 'Step #' + ( this.index + 1 );

    }

  },
  methods: {

    toggle_edit: function() {

      if( this.editing )  {

        this.editing = false;

      } else  {

        this.editing = true;

      }

    },

    remove_step: function() {

      const payload = {
        index: this.index
      }

      this.$emit( 'remove_step', payload );

    },

    update_title: function( data ) {

      this.step_heading = data.value;

    },

    update_description: function( data )  {

      this.step_description = data.value;

    },

    update_step: function() {

      this.toggle_edit();

      const payload = {
        index: this.index,
        data: {
          heading: this.step_heading,
          description: this.step_description,
        }
      };

      this.heading = this.step_heading;
      this.description = this.step_description;
      this.$emit( 'update_step', payload );

    },

    move_up: function() {

      this.$emit( 'change_index', {
        from: this.index,
        to: this.index - 1
      } );

    },

    move_down: function() {

      this.$emit( 'change_index', {
        from: this.index,
        to: this.index + 1
      } );

    }

  },
  components: { Input_Wysiwyg, Input_Text }
}
</script>

<style scoped>

</style>