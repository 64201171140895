<template>
  <div id="app">
    <Header/>
    <router-view v-if="data_loaded"></router-view>
    <div className="loader" v-else>

      <div className="main">
        <div className="lds-dual-ring"></div>
        <div>Loading</div>
      </div>

    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import store from "@/store/store";

export default {
  name: 'HAZID',
  components: {
    Header
  },
  metaInfo: {
    title: 'HAZID',
    titleTemplate: '%s | Renfrewshire Leisure'
  },
  computed: {

    data_loaded: function () {

      if (!store.state.data_loaded && store.state.ajax_loading) {

        return false;

      }

      return true;

    }

  },
  mounted: function () {

  }

}
</script>

<style scoped lang="css">
@import "style.css";
</style>
