<template>
  <div class="blocks">

    <section class="block">

      <section>
        <h3 v-text="title"></h3>
        <p v-html="intro"></p>
      </section>

      <section class="form">
        <component
                   v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in form_fields" :key="form_field.name" v-bind:items="form_field.choices" v-bind:checked_items="form_field.checked_items" v-bind:field_index="index"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'sops_procedure_fields[' + index + ']'"
        ></component>
      </section>

      <Sub_Steps v-bind:steps="sub_steps" v-on:finish="save_step"></Sub_Steps>

    </section>

  </div>
</template>

<script>
import Sub_Steps from "@/components/Sub_Steps";
import Input_Number from "@/components/Input_Number";
import Input_Checkbox from "@/components/Input_Checkbox";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Form_Summary from "@/components/Form_Summary";
import store from "@/store/store";

export default {
  name: "Procedure",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Checkbox, Sub_Steps },
  data: function() {
    return {
      is_loading: false,
      id_helper: 100,
      title: store.state.procedure_texts.title,
      intro: store.state.procedure_texts.intro,
      form_fields: store.state.sops_procedure_fields,
      sub_steps: store.state.sops_procedure_steps
    }
  },
  metaInfo: {
    title: 'Purpose'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    }
  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function( e ) {

      if( e.type === 'start' )  {

        this.$router.push( { name: 'roles_and_responsibilities' } );

      } else  {

        this.$router.push( { name: 'appendices' } );

      }

/*
      if( this.is_loading ) {

        return;

      }
      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {
// vrat mi novy token v ktorom bude post id
// uloz novy token
        // zmen akciu z new na edit (ak je new)
        console.log( result );
        this.toggle_loading();
        store.commit( 'update_submission', result.data.refresh_token );
        // go next
        this.$router.push( { name: 'hazards' } );

      } );

      return false;
 */
      return false;
    },
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    }

  },
  mounted() {

    store.commit( 'set_step', 3 );

  }
}
</script>

<style scoped>

</style>