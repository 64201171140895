<template>
  <div class="blocks">

    <section class="block">

      <section>
        <h3 v-text="title"></h3>
        <p v-html="intro"></p>
      </section>

      <section class="form no_padding">
<!--        <p v-html="documents" style="white-space: pre"></p>-->
        <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in documents" :key="form_field.name" v-bind:items="form_field.choices" v-bind:checked_items="form_field.checked_items" v-bind:field_index="index"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'sops_documents_documents[' + index + ']'" v-bind:fields_config="form_field.fields"
                   v-bind:add_new_label="'Add Document'" v-bind:repeater_component="'Document'"
                   v-on:add_repeater_item="add_repeater_item" v-on:remove_repeater_item="remove_repeater_item" v-on:update_repeater_item="update_repeater_item"
        ></component>
      </section>

      <section>
        <h3 v-text="title_2"></h3>
        <p v-html="intro_2"></p>
      </section>

      <section class="form no_padding">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in form_fields" :key="form_field.name" v-bind:items="form_field.choices" v-bind:checked_items="form_field.checked_items" v-bind:field_index="index"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'sops_documents_fields[' + index + ']'"
        ></component>
      </section>

      <section class="button_row">
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="save_step" v-text="next_step_text"></a>
        <router-link :to="{ name: 'appendices' }" role="button">Back</router-link>
      </section>
    </section>

  </div>
</template>

<script>
import Input_Number from "@/components/Input_Number";
import Input_Checkbox from "@/components/Input_Checkbox";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Input_Repeater from "@/components/Input_Repeater";
import Input_Toggle from "@/components/Input_Toggle";
import Form_Summary from "@/components/Form_Summary";
import store from "@/store/store";

export default {
  name: "Documents",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Checkbox, Input_Repeater, Input_Toggle },
  data: function() {
    return {
      is_loading: false,
      title: store.state.documents_texts.title,
      intro: store.state.documents_texts.intro,
      title_2: store.state.documents_texts.title_2,
      intro_2: store.state.documents_texts.intro_2,
      form_fields: store.state.sops_documents_fields,
      documents: store.state.sops_documents_documents
    }
  },
  metaInfo: {
    title: 'Documents'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    }
  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function() {


            if( this.is_loading ) {

              return;

            }
            this.toggle_loading();

            store.dispatch( 'submit' ).then( result => {
      // vrat mi novy token v ktorom bude post id
      // uloz novy token
              // zmen akciu z new na edit (ak je new)
              console.log( result );
              this.toggle_loading();
              store.commit( 'update_submission', result.data.refresh_token );
              // go next
              this.$router.push( { name: 'distribution' } );

            } );

            return false;

    },

    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    },

    add_repeater_item : function( payload ) {

      store.commit( 'add_repeater_item', payload )

    },

    remove_repeater_item : function( payload ) {

      store.commit( 'remove_repeater_item', payload )

    },

    update_repeater_item : function( payload ) {

      store.commit( 'update_repeater_item', payload )

    },

  },
  mounted() {

    store.commit( 'set_step', 5 );

  }
}
</script>

<style scoped>

</style>