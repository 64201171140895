<template>
  <div class="form_item input_textarea">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <quill-editor
          :content="value"
          :options="editor_options"
          @change="changed( $event )"
      />
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import Form_Label from "@/components/Form_Label";
export default {
  name: "Input_Wysiwyg",
  components: { Form_Label, quillEditor },
  props:{
    label: String,
    name: String,
    value: String,
    info: String,
    store: String
  },
  data: function()  {
    return  {
      editor_options: {
        modules : {
          toolbar: ['bold', 'italic', 'underline', 'link', { 'list' : 'ordered' }, { 'list' : 'bullet' }]
        }
      }
    }
  },
  methods: {
    changed : function( value ) {

      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: value.html
      } );
    },
  }
}
</script>

<style scoped>

</style>