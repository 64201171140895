import { render, staticRenderFns } from "./Roles_And_Responsibilities.vue?vue&type=template&id=3dea2af2&scoped=true&"
import script from "./Roles_And_Responsibilities.vue?vue&type=script&lang=js&"
export * from "./Roles_And_Responsibilities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dea2af2",
  null
  
)

export default component.exports