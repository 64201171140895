<template>
  <div>
    <!--div style="white-space: pre" v-html="steps"></div-->
    <section class="hazard_navigation">

      <a href="#" class="link" @click.prevent="prev_step"><i class="icon-arrow-left"></i></a>

      <div class="hazard_items" v-bind:class="{opened: items_opened}">

        <a href="#" class="hazard_item current_item" v-on:click.prevent="toggle_items">
          <div class="hazard_icon" v-text="( current_step_idx + 1 ) + '.' "></div>
          <div class="hazard_description">
            <strong>{{current_step.title}}</strong>
            <em>{{current_step.info}}</em>
          </div>
          <i v-bind:class="{ 'icon-chevron-down' : !items_opened, 'icon-chevron-up' : items_opened }"></i>
        </a>

        <a href="#" class="hazard_item" v-for="( step, index ) in steps" :key="step.id" v-show="items_opened" v-bind:class="{ active: current_step.id === step.id }" v-on:click.prevent="go_to_step( step, index )">
          <div class="hazard_icon" v-text="( index + 1 ) + '.' "></div>
          <div class="hazard_description">
            <strong>{{step.title}}</strong>
            <em>{{step.info}}</em>
          </div>
        </a>

      </div>

      <a href="#" class="link" @click.prevent="next_step"><i class="icon-arrow-right"></i></a>

    </section>

    <section v-bind:class="{ form: 1 === 1, no_padding: current_step_idx === 4 }">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in current_step.fields" :key="form_field.name" v-bind:items="form_field.choices" v-bind:checked_items="form_field.checked_items" v-bind:field_index="index"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info" v-bind:fields_config="form_field.fields"
                   v-on:changed="update_field" v-bind:store="'sops_procedure_steps[' + current_step_idx + '].fields[' + index + ']'" v-on:add_item="add_item" v-on:update_item="update_item" v-on:remove_item="remove_item"
                   v-bind:add_new_label="'Add Step'" v-bind:repeater_component="'Step'"
                   v-on:add_repeater_item="add_repeater_item" v-on:remove_repeater_item="remove_repeater_item" v-on:update_repeater_item="update_repeater_item" v-on:change_repeater_item_index="change_repeater_item_index"
        ></component>
    </section>

    <section class="button_row">
      <a href="#" role="button" class="primary" @click.prevent="next_step">Continue</a>
      <a href="#" role="button" class="tertiary" @click.prevent="prev_step">Back</a>
    </section>

  </div>
</template>

<script>

import Input_Number from "@/components/Input_Number";
import Input_Repeater from "@/components/Input_Repeater";
import Input_Checkbox from "@/components/Input_Checkbox";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Form_Summary from "@/components/Form_Summary";
import store from "@/store/store";

export default {
  name: "Sub_Steps",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Checkbox, Input_Repeater },
  props: {
    steps: Array
  },
  data: function() {
    return {
      step_root: '/procedure/',
      is_loading: false,
      id_helper: 100,
      title: store.state.procedure_texts.title,
      intro: store.state.procedure_texts.intro,
      items_opened : false,
      current_step_idx : 0
    }
  },
  metaInfo: {
    title: 'Purpose'
  },
  computed: {
    form_fields: function() {

      return this.current_step().fields;

    },
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    },
    current_step : function() {

      return this.steps[ this.current_step_idx ];

    },

  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },

    prev_step : function()  {

      const next_step_idx = this.current_step_idx - 1;

      if( next_step_idx < 0 ) {

        this.$emit( 'finish', {
          type: 'start'
        } );
        return;

      }

      this.$router.push( { path: '/procedure/' + this.steps[ next_step_idx ].id } );

    },

    next_step : function()  {

      const next_step_idx = this.current_step_idx + 1;
      const len = this.steps.length - 1;

      if( next_step_idx > len ) {

        this.$emit( 'finish', {
          type: 'end'
        } );
        return;

      }

      this.$router.push( { path: '/procedure/' + this.steps[ next_step_idx ].id } );

    },

    toggle_items : function() {

      if( this.items_opened ) {

        this.items_opened = false;

      } else  {

        this.items_opened = true;

      }

    },

    go_to_step : function( step, index ) {

      this.toggle_items();
      this.current_step_idx = index;
      this.$router.push( { path: '/procedure/' + step.id } );

    },

    save_step: function() {

      /*
            if( this.is_loading ) {

              return;

            }
            this.toggle_loading();

            store.dispatch( 'submit' ).then( result => {
      // vrat mi novy token v ktorom bude post id
      // uloz novy token
              // zmen akciu z new na edit (ak je new)
              console.log( result );
              this.toggle_loading();
              store.commit( 'update_submission', result.data.refresh_token );
              // go next
              this.$router.push( { name: 'hazards' } );

            } );

            return false;
       */
      this.$router.push( { name: 'roles_and_responsibilities' } );
      return false;
    },
    update_field: function( payload )  {

console.log('update_field', payload);

      store.commit( 'update_field', payload )

    },
    add_item: function( payload )  {

      const risk_id = payload.name.replace( '[]', '' ) + '_' + this.id_helper;
      this.id_helper += 1;

      payload.id = risk_id;

      store.commit( 'add_checkbox_choice', payload );

    },
    remove_item : function( payload )  {

      store.commit( 'remove_checkbox_choice', payload );

    },
    update_item : function( item )  {
console.log('update item', item);
      store.commit( 'update_checkbox_choice', item );

    },

    add_repeater_item : function( payload ) {

      store.commit( 'add_repeater_item', payload )

    },

    remove_repeater_item : function( payload ) {
console.log('ffff');
      store.commit( 'remove_repeater_item', payload )

    },

    update_repeater_item : function( payload ) {
console.log('update', payload)
      store.commit( 'update_repeater_item', payload )

    },

    change_repeater_item_index : function ( payload ) {

      store.commit( 'change_repeater_item_index', payload );

    }

  },
  watch: {
    '$route' ( to, from ) {

      for( let i = 0; i < this.steps.length; i++ )  {

        if( to.params.step === this.steps[ i ].id ) {

          this.current_step_idx = i;
          break;

        }

      }

      console.log( to, from );

    }
  },
  mounted() {

    store.commit( 'set_step', 3 );

    if( this.$route.params.step === null )  {

      return;

    }

    for( let i = 0; i < this.steps.length; i++ )  {

      if( this.$route.params.step === this.steps[ i ].id ) {

        this.current_step_idx = i;
        break;

      }

    }

  }
}
</script>

<style scoped>

</style>