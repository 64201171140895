<template>

  <div class="person">

    <section class="person_summary">

      <div>
        <p class="person_no">{{step}}</p>
      </div>

      <div class="person_desc">
        <h3>{{step_title}}</h3>
      </div>

      <div class="person_controls">
        <a href="#edit" role="button" class="secondary" v-on:click.prevent="toggle_edit">Edit</a>
        <a href="#delete" role="button" class="secondary" v-on:click.prevent="remove_step">Delete</a>
      </div>

    </section>

    <section v-if="editing" class="form person_form">
<!--<p v-html="value"></p>-->
<!--<p v-html="fields_config"></p>-->

      <Input_Radio
          v-bind:label="field_type.label" v-bind:name="field_type.name + index" v-bind:value="value_type" v-bind:items="field_type.choices" v-on:changed="update_step( 'type', $event, false)"
        ></Input_Radio>
      <Input_Text
          v-bind:label="field_title_and_reference_number.label" v-bind:name="value_title_and_reference_number" v-bind:value="field_title_and_reference_number.value" v-on:changed="update_step( 'title_and_reference_number', $event, false)" v-if="value.type === 'qms'"
      ></Input_Text>

      <Input_Text
          v-bind:label="field_url.label" v-bind:name="field_url.name" v-bind:value="value_url" v-on:changed="update_step( 'url', $event, false)" v-if="value.type === 'url'"
      ></Input_Text>

      <Input_File
          v-bind:label="field_file_name.label" v-bind:index="index" v-bind:name="field_file_name.name" v-bind:value="value_file_name" v-bind:file="value_file_name.file" v-bind:file_field="field_file" v-on:uploaded="update_step( 'file', $event, false );" v-on:changed="update_step( 'file_name', $event, false)" v-if="value.type === 'file'"
      ></Input_File>

      <div class="button_row">
        <a href="#save" class="primary" role="button" v-on:click.prevent="toggle_edit">Ok</a>
        <a href="#cancel" role="button" v-on:click.prevent="toggle_edit">Cancel</a>
      </div>

    </section>

  </div>
</template>

<script>
import Input_Text from "@/components/Input_Text";
import Input_Radio from "@/components/Input_Radio";
import Input_File from "@/components/Input_File";

function Repeater_Field( data, index ) {

  this.value = data.value;
  this.key = data.key;
  this.name = data.name;
  this.label= data.label;
  this.type = data.type;

  if( this.type == 'radio' )  {

    this.choices = [];
    for( let i = 0; i < data.choices.length; i++ )  {

      this.choices.push( {
        id: data.choices[ i ].id + '__' + index,
        label: data.choices[ i ].label,
        value: data.choices[ i ].value,
      } );

    }

  }

  if( this.type == 'text' ) {

    this.info = data.info;

  }


}

export default {
  name: "Document",
  props: {
    form_field: Object,
    index: Number,
    step_count: Number,
    value: Object,
    fields_config: Array
  },
  watch:  {
    step_count: function()  {
      this.editing = false;
    }
  },
  data: function()  {
    return {
      editing: false,
      field_type: null,
      field_title_and_reference_number: null,
      field_url: null,
      field_file_name: null,
      field_file: null,
    }
  },
  mounted() {

    for ( let i = 0; i < this.fields_config.length; i++ ) {

      const f = this.fields_config[ i ];

      if( f.name === 'type' ) {

        this.field_type = new Repeater_Field( f, this.index );

      } else if( f.name === 'title_and_reference_number' )  {

        this.field_title_and_reference_number = f;

      } else if( f.name === 'url' ) {

        this.field_url = f;

      } else if( f.name === 'file_name' ) {

        this.field_file_name = f;

      } else if( f.name === 'file' )  {

        this.field_file = f;

      }

    }

    this.editing = true;

  },
  computed: {

    step_title: function()  {

      if( this.value.type === 'file' && this.value.file_name !== '' ) {

        return this.value.file_name;

      }

      if( this.value.type === 'qms' && this.value.title_and_reference_number !== '' ) {

        return this.value.title_and_reference_number;

      }

      if( this.value.type === 'url' && this.value.url !== '' ) {

        return this.value.url;

      }

      return 'Unnamed';

    },

    value_type: function()  {

      return this.value.type;

    },
    value_title_and_reference_number: function()  {

      return this.value.title_and_reference_number;

    },
    value_url : function()  {

      return this.value.url;

    },
    value_file_name : function()  {

      return {
        file_name: this.value.file_name,
        file: this.value.file
      }

    },
    step: function()  {

      return 'Document #' + ( this.index + 1 );

    }

  },
  methods: {

    toggle_edit: function() {

      if( this.editing )  {

        this.editing = false;

      } else  {

        this.editing = true;

      }

    },

    remove_step: function() {

      const payload = {
        index: this.index
      }

      this.$emit( 'remove_step', payload );

    },

    update_step: function( key, data, close ) {

      if( close ) {

        this.toggle_edit();

      }

      let payload = {
        index: this.index,
        data: {
          type: this.value.type,
          title_and_reference_number: this.value.title_and_reference_number,
          url: this.value.url,
          file_name: this.value.file_name,
          file: this.value.file
        }
      };

      payload.data[ key ] = data.value;

      this.$emit( 'update_step', payload );

    }

  },
  components: { Input_File, Input_Radio, Input_Text }
}
</script>

<style scoped>

</style>