<template>

  <div class="person">

    <section class="person_summary">

      <div>
        <p class="person_no">{{step}}</p>
      </div>

      <div class="person_desc">
        <h3>{{value_name}}</h3>
      </div>

      <div class="person_controls">
        <a href="#edit" role="button" class="secondary" v-on:click.prevent="toggle_edit">Edit</a>
        <a href="#delete" role="button" class="secondary" v-on:click.prevent="remove_step">Delete</a>
      </div>

    </section>

    <section v-if="editing" class="form person_form">

      <Input_Text
          v-bind:label="field_name.label" v-bind:name="field_name.name" v-bind:value="value_name" v-on:changed="update_step( 'name', $event, false )"
      ></Input_Text>

      <Input_Text
          v-bind:label="field_job_title.label" v-bind:name="field_job_title.name" v-bind:value="value_job_title" v-on:changed="update_step( 'job_title', $event, false )"
      ></Input_Text>

      <Input_Text
          v-bind:label="field_email.label" v-bind:name="field_email.name" v-bind:value="value_email" v-on:changed="update_step( 'email', $event, false )"
      ></Input_Text>

      <div class="button_row">
        <a href="#save" class="primary" role="button" v-on:click.prevent="toggle_edit">Ok</a>
        <a href="#cancel" role="button" v-on:click.prevent="toggle_edit">Cancel</a>
      </div>

    </section>

  </div>
</template>

<script>
import Input_Text from "@/components/Input_Text";

export default {
  name: "Person",
  props: {
    name: String,
    index: Number,
    step_count: Number,
    value: Object,
    fields_config: Array
  },
  watch:  {
    step_count: function()  {
      this.editing = false;
    }
  },
  data: function()  {
    return {
      field_name: null,
      field_job_title: null,
      field_email: null,
      editing: false
    }
  },
  mounted() {

    for ( let i = 0; i < this.fields_config.length; i++ ) {

      const f = this.fields_config[ i ];

      if( f.name === 'name' ) {

        this.field_name = f;

      } else if( f.name === 'job_title' )  {

        this.field_job_title = f;

      } else if( f.name === 'email' ) {

        this.field_email = f;

      }

    }

    this.editing = true;

  },
  computed: {

    value_name: function()  {

      return this.value.name;

    },

    value_job_title: function() {

      return this.value.job_title;

    },

    value_email: function() {

      return this.value.email;

    },

    step: function()  {

      return 'Person #' + ( this.index + 1 );

    }

  },
  methods: {

    toggle_edit: function() {

      if( this.editing )  {

        this.editing = false;

      } else  {

        this.editing = true;

      }

    },

    remove_step: function() {

      const payload = {
        index: this.index
      }

      this.$emit( 'remove_step', payload );

    },

    update_step: function( key, data, close ) {

      if( close ) {

        this.toggle_edit();

      }

      let payload = {
        index: this.index,
        data: {
          name: this.value.name,
          job_title: this.value.job_title,
          email: this.value.email
        }
      };

      payload.data[ key ] = data.value;

      this.$emit( 'update_step', payload );

    },

    move_up: function() {

      this.$emit( 'change_index', {
        from: this.index,
        to: this.index - 1
      } );

    },

    move_down: function() {

      this.$emit( 'change_index', {
        from: this.index,
        to: this.index + 1
      } );

    }

  },
  components: { Input_Text }
}
</script>

<style scoped>

</style>