<template>
  <section class="form_item input_toggle"  v-bind:class="{ active : value === true, inactive : value === false }">
    <div class="toggle_description">
      <strong>{{label}}</strong>
      <em>{{info}}</em>
    </div>
    <div class="toggle_controls">
      <div>
        <button v-on:click="enable">Yes</button>
        <button v-on:click="disable">No</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    label : String,
    info : String,
    id : String,
    value : Boolean,
    name : String,
    store: String
  },
  data: function()  {

    return {
      // state: null
    }

  },
  methods:  {

    enable : function() {

      // this.state = true;
      // this.$emit( 'update_item', { state: true, id: this.id } );
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: true
      } );

    },
    disable : function()  {

      // this.state = false;
      // this.$emit( 'update_item', { state: false, id: this.id } );
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: false
      } );

    }

  }
}
</script>

<style scoped>

</style>