<template>
  <header>
    <div class="logo">
      <img src="../assets/renfrewshire_leisure_logo.png"  alt="SOPS Report Builder"/>
      <h1><span>SOPS</span> <span>Report Builder</span></h1>
      <span>SOPS</span>
    </div>
    <nav class="form_navigation_tabs">
        <div class="step" v-for="( step, index ) in steps" :key="step.id" v-bind:class="{ 'active' : index === current_step, 'prev' : index === current_step - 1, 'next' : index === current_step + 1 }">
          <router-link :to="{ name: step.id }">{{ step.name }}</router-link>
        </div>
    </nav>
  </header>
</template>

<script>
import store from '../store/store.js';

export default {
  name: "Header",
  data: function()  {

    return {
      steps: store.state.steps
    }

  },
  computed: {

    current_step: function()  {

      return store.state.current_step;

    }

  }
}
</script>

<style scoped>

</style>