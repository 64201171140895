<template>
  <div class="form_item input_radio">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <div v-for="( item, index ) in items" :key="item.id">
        <p>
          <input type="radio" v-bind:id="item.id" v-bind:name="name" :checked="( item.value === value )" v-bind:value="item.value" v-on:change="change_item({ value: $event.target.value, id: item.id, name: name, index: index })"/>
          <label :for="item.id">{{item.label}}</label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";

export default {
  name: "Input_Radio",
  components: { Form_Label },
  props: {
    label: String,
    name: String,
    info: String,
    field_index: Number,
    items: Array,
    value: String,
    store: String
  },
  methods: {

    change_item: function( payload ) {
      console.log('changed', payload);

      payload.store = this.store;
      payload.field_index = this.$props.field_index;
      this.$emit( 'changed', payload );

    },

  }

}
</script>

<style scoped>

</style>